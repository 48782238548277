import {httpReq} from "@/htttpReq";

const routePrefix = '/ordering-portal/account-information-maintenance/business-details/licences'

export default {

  index: () => httpReq.get(`${routePrefix}`),
  update: (id, payload) => httpReq.put(`${routePrefix}/${id}`, payload),
  store: (payload) => httpReq.post(`${routePrefix}`, payload),
  destroy: (id) => httpReq.delete(`${routePrefix}/${id}`),


}
