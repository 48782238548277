<template>
  <div>
    <div class="tw-flex tw-flex-col">
      <div class="tw-overflow-x-auto tw-sm:-mx-6 tw-lg:-mx-8">
        <div class="tw-py-2 tw-inline-block tw-min-w-full tw-sm:px-6 tw-lg:px-8">
          <div class="tw-overflow-hidden">
            <Form
              v-slot="{ errors }"
              autocomplete="off"
              @submit="submit"
            >


              <div class="tw-overflow-auto tw-shadow">
                <table class="tw-min-w-full tw-divide-y tw-divide-gray-200 tw-divide-solid">
                  <thead class="tw-bg-gray-50">

                  <th class="tw-w-7 tw-px-1.5 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
                      scope="col">
                    Licence type
                  </th>
                  <th class="tw-w-7 tw-px-1.5 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
                      scope="col">
                    Licence number
                  </th>
                  <th class="tw-w-7 tw-px-1.5 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
                      scope="col">
                    Expiry Date
                  </th>
                  <th class="tw-w-7 tw-px-1.5 tw-py-3 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
                      scope="col">

                  </th>

                  </thead>
                  <tbody class="tw-divide-solid tw-divide-y tw-divide-gray-200">

                  <tr v-for="(licence , index) in licences" :key="licence.id" class="tw-border-b">

                    <td
                      v-if="!licence.is_editing"
                      class="tw-px-1.5 tw-py-4 tw-whitespace-nowrap">
                      {{ licence.licence_type }}
                    </td>
                    <td
                      v-else
                      class="tw-px-1.5 tw-whitespace-nowrap">

                      <Field v-model="licence.licence_type"
                             :rules="{required:true, max:128}"
                             class="form__input  tw-border-gray-200 tw-text-black-gray tw-text-sm tw-px-2 " label="licence type"
                             name="licence_type"
                             placeholder="Licence type*"
                             type="text"
                      />
                      <br>
                      <span class="form__error">{{ errors.licence_type }}</span>

                    </td>


                    <td
                      v-if="!licence.is_editing"
                      class="tw-px-1.5 tw-py-4 tw-whitespace-nowrap">
                      {{ licence.licence_number }}
                    </td>
                    <td
                      v-else
                      class="tw-px-1.5  tw-whitespace-nowrap">
                      <Field v-model="licence.licence_number"
                             :rules="{required:true, max:128}"
                             class="form__input  tw-border-gray-200 tw-text-black-gray tw-text-sm tw-px-2 " label="licence number"
                             name="licence_number"
                             placeholder="Licence number*"
                             type="text"
                      />
                      <br>
                      <span class="form__error">{{ errors.licence_number }}</span>
                    </td>

                    <td
                      v-if="!licence.is_editing"
                      class="tw-px-1.5 tw-py-4 tw-whitespace-nowrap">
                      {{ $filters.formatDate(licence.expiry_date) }}
                    </td>

                    <td

                      v-else
                      class="tw-px-1.5  tw-whitespace-nowrap">
                      <div>
                        <Datepicker
                          :id="`datepicker_header_${licence.id}`"
                          v-model="licence.expiry_date"
                          :clearable="false"
                          :enable-time-picker="false"
                          :minDate="new Date()"
                          format="dd/MM/yyyy"
                          name="dateRange"
                          placeholder="Select the expiry date"
                          required
                          style="z-index: 100"
                        />
                      </div>
                      <br>

                      <span v-if="!licence.expiry_date && index !== 0" class="form__error">Please select a valid date.</span>

                    </td>

                    <td
                      class="tw-px-1.5 tw-py-4 tw-whitespace-nowrap">
                      <button v-if="!licence.is_editing"
                              class="tw-bg-transparent tw-border-0 tw-cursor-pointer"
                              @click.stop="licence.is_editing = true">
                        <svg-icon name="solid/pen"/>
                      </button>
                      <button v-if="licence.is_editing"
                              class="tw-bg-transparent tw-border-0 tw-cursor-pointer"
                              type="submit"
                              @click.stop="currentlyEditingLicense = licence"
                      >
                        <svg-icon name="solid/check"/>

                      </button>
                      <button class="tw-bg-transparent tw-border-0 tw-cursor-pointer"
                              @click.prevent="deleteLicence(licence.id,index)">
                        <svg-icon name="solid/xmark"/>

                      </button>
                      <button v-if="licences.length === (index + 1)"
                              class="tw-bg-transparent tw-border-0 tw-cursor-pointer"
                              @click.stop="licences.push({is_editing:true})"
                      >
                        <svg-icon name="solid/circle-plus"/>

                      </button>
                    </td>

                  </tr>
                  </tbody>
                </table>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalMixins from '@/Mixins/GlobalMixins.js'
import LicencesEndPoints from "../../../routes/LicencesEndPoints";
import Datepicker from "vue3-datepicker";
//import "vue3-date-time-picker/dist/main.css";
import moment from 'moment'

export default {
  name: "index",
  components: {
    Datepicker
  },
  data() {
    return {
      currentlyEditingLicense: {},
      licences: []
    }
  },
  async mounted() {
    await this.index()
  },
  mixins: [GlobalMixins],
  methods: {
    async index() {
      this.startLoader()
      try {
        this.licences = (await LicencesEndPoints.index()).data.data
        if (this.licences.length === 0) this.licences.push({is_editing: true})
        this.stopLoader()
      } catch (error) {
        this.showErrorModal(error)
        this.stopLoader()
      }
    },
    async deleteLicence(id, index) {
      if (!id) {
        this.licences.splice(index, 1)
        return
      }
      try {
        await LicencesEndPoints.destroy(id)
        await this.index()
      } catch (error) {
        this.showErrorModal(error)
        this.stopLoader()
      }
    },
    async submit() {
      let editedLicence = _.find(this.licences, {id: this.currentlyEditingLicense.id})
      this.startLoader()
      //Editing
      if (editedLicence) {
        editedLicence.expiry_date = moment(this.currentlyEditingLicense.expiry_date).format('YYYY-MM-DD')
        try {
          (await LicencesEndPoints.update(editedLicence.id, editedLicence))
          editedLicence.is_editing = false
          this.stopLoader()
        } catch (error) {
          this.showErrorModal(error)
          this.stopLoader()
        }
        editedLicence.is_editing = false
      } else {
        //New licence
        try {
          let payload = _.cloneDeep(this.currentlyEditingLicense)
          payload.expiry_date = moment(this.currentlyEditingLicense.expiry_date).format('YYYY-MM-DD')

          await (LicencesEndPoints.store(payload))
          await this.index()

        } catch (error) {
          this.showErrorModal(error)
          this.stopLoader()
        }
      }

      this.stopLoader()

    }
  },
  computed: {}
}
</script>

<style scoped>

</style>
